import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const KlassischeDermatologischeErkrankungen = ({
	data,
	location,
	...props
}) => {
	return (
		<Layout
			location={location}
			title="Klassische dermatologische Erkrankungen"
			noSlider
			{...props}
		>
			<p>
				Hierzu zählen wir alle akut auftretenden oder uns chronisch
				quälenden Erkrankungen an der Haut.{' '}
			</p>
			<p>
				Zu aller erst gilt es, Ihnen, lieber Patient, die Beschwerden zu
				nehmen.
			</p>
			<p>
				Der nächste Schritt ist erforderlich um die Ursache Ihrer
				Erkrankung erkennen zu können und damit die Ursache abzustellen,
				die Erkrankung quasi auszutrocknen.
			</p>

			<p>
				Anlagebedingte Erkrankungen, wie zum Beispiel eine Neurodermitis
				oder eine Schuppenflechte gilt es ebenso zu identifizieren wie
				allergologische Ursachen einer Hauterkrankung oder infektiöse
				Ursachen, um nur einige zu nennen.
			</p>
			<p>
				Die Ursachenabklärung in der Dermatologie ist oftmals eine
				Herausforderung für den Patienten und auch den Dermatologen.
			</p>
			<p>
				In jedem Fall geben wir Ihnen Hilfe und Halt und ebnen den Weg
				zur Besserung Ihrer Hauterkrankung.
			</p>
			<p>
				Kurzfristige Termine, schnelle, einfühlsame, professionelle
				Hilfe in unserer Praxis. Wenn notwendig verschaffen wir Ihnen
				schnellstmöglich einen Platz in der regionalen Hautklinik, ggf.
				auch in einer spezialisierten Klinik einer anderen Stadt.
			</p>
			<p>
				Unser Ziel ist es, Ihr Licht am Ende des Korridors schnell zu
				erreichen und die Gedanken wieder für schöne Dinge des Lebens
				frei zu bekommen.
			</p>

			<Questions
				title="klassischen dermatologischen Erkrankungen"
				prefix="um"
			/>
		</Layout>
	);
};

export default KlassischeDermatologischeErkrankungen;
